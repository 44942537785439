import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { motion } from "framer-motion";
import LandingLayout from "../../components/landings/LandingLayout";
import Container from "../../components/Container";
import HeroLanding from "../../components/HeroLanding";
import SecondaryButton from "../../components/SecondaryButton";
import CardColor from "../../components/CardColor";
import ProductModal from "../../components/ProductModal";
import { theme } from "../../themeV2";
import blueIcon from "../../images/products/share-class-radar/icon-blue.png";
import greenIcon from "../../images/products/share-class-radar/icon-green.png";
import purpleIcon from "../../images/products/share-class-radar/icon-purple.png";
import orangeIcon from "../../images/products/share-class-radar/icon-orange.png";
import heroImg from "../../images/products/share-class-radar/hero.png";
import radarImg from "../../images/products/share-class-radar/radar.png";
import searchVideoPoster from "../../images/products/share-class-radar/search-video-poster.png";
const searchVideo = "https://app.allfunds.com/docs/cms/search_video_3c50f7d17b.mp4";

const metadata = {
  title: "Share Class Radar",
  description:
    "Powerful fund class search tool. Comply with MiFID regulations using advanced filters and comprehensive data.",
};

const CARDS_ITEMS = [
  {
    icon: greenIcon,
    color: theme.palette.colors.green.main,
    title: <Trans>Access</Trans>,
    description: (
      <Trans>
        Access direct share class details and costs from Fund Houses and Allfunds.
      </Trans>
    ),
  },
  {
    icon: orangeIcon,
    color: theme.palette.colors.orange.dark,
    title: <Trans>Utilize</Trans>,
    description: (
      <Trans>
        Utilize ranking matrices validated by CNMV for regulatory compliance.
      </Trans>
    ),
  },
  {
    icon: blueIcon,
    color: theme.palette.colors.blue.main,
    title: <Trans>Filter</Trans>,
    description: (
      <Trans>
        Filter by regulatory requirements and link fund house classes accordingly.
      </Trans>
    ),
  },
  {
    icon: purpleIcon,
    color: theme.palette.colors.purple.main,
    title: <Trans>Compare</Trans>,
    description: (
      <Trans>
        Compare standardized prospectus details reviewed by experts for accuracy.
      </Trans>
    ),
  },
];

const getAnimationProps = (direction) => ({
  initial: "hidden",
  whileInView: "visible",
  viewport: { once: true, amount: 0.8 },
  transition: { duration: 0.8 },
  variants: {
    hidden: { opacity: 0, y: direction === "down" ? 70 : -70 },
    visible: { opacity: 1, y: 0 },
  },
});

function AnimatedDiv({ isMobile, direction, children }) {
  if (isMobile) {
    return <div>{children}</div>;
  }
  return <motion.div {...getAnimationProps(direction)}>{children}</motion.div>;
}

function ShareClassRadar() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showModal, setShowModal] = useState(false);
  const [subTitle, setSubTitle] = useState(null);

  const handleOnclickInfo = (key) => {
    setSubTitle(key);
    setShowModal(true);
  };

  const renderRequestButton = (key) => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => handleOnclickInfo(key)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const preTitle = ({
    color = `${theme.palette.colors.orange.dark}`,
    text,
    align = "center",
  }) => {
    return (
      <Typography
        fontWeight={700}
        color={color}
        textAlign={align}
        fontSize={{ xs: "18px", sm: "20px" }}
      >
        {text}
      </Typography>
    );
  };

  const renderCard = ({ color, icon, title, description, key }) => {
    return (
      <Grid item xs={12} sm={6} md={3} sx={{ transform: { sm: key % 2 === 0 ? "translateY(0)" : "translateY(24px)" } }}>
        <CardColor
          mt={0}
          color={color}
          icon={icon}
          title={title}
          description={description}
        />
      </Grid>
    );
  };

  return (
    <LandingLayout seoTitle={metadata.title} seoDescription={metadata.description}>
      <HeroLanding
        title={<Trans>Comply with MiFID regulation and new requirements</Trans>}
        description={
          <Trans>
            Powerful fund class search tool. Comply with MiFID regulations using
            advanced filters and comprehensive data.
          </Trans>
        }
        handleOnclickInfo={() => handleOnclickInfo("Share Class Radar")}
        multimedia={heroImg}
        widthImage="100%"
        kind="Share Class Radar"
        kindColor={theme.palette.colors.orange.dark}
        grid={{
          columnLeft: { md: 6 },
          columnRight: { md: 6 },
        }}
      />
      <Container pb={{ xs: 8, md: 14.5, lg: 8 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          flexWrap={{ xs: "wrap-reverse" }}
        >
          <Grid
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <video
              muted
              autoPlay
              playsInline
              poster={searchVideoPoster}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            >
              <source src={searchVideo} type="video/mp4" />
            </video>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack spacing={2}>
              <Stack spacing={1}>
                {preTitle({ text: "Share Class Search", align: "left" })}
                <Typography variant="h2">
                  <Trans>Powerful Fund Class Search</Trans>
                </Typography>
              </Stack>
              <ul style={{ padding: "0 0 0 18px", opacity: "0.6" }}>
                <li>
                  <Trans>Search by ISIN, Fund House, and Family Group</Trans>
                </li>
                <li>
                  <Trans>Upload Excel lists for instant information</Trans>
                </li>
                <li>
                  <Trans>Save selections for regulatory compliance</Trans>
                </li>
                <li>
                  <Trans>
                    Integrated with Allfunds Connect for intuitive selection
                  </Trans>
                </li>
                <li>
                  <Trans>Access the most accurate data sources</Trans>
                </li>
              </ul>
              <Box
                display="flex"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                {renderRequestButton("Share Class Search")}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Box backgroundColor={theme.palette.colors.grey.light}>
        <Container py={{ xs: 8, md: 10 }}>
          <Stack flexDirection="column" alignItems="center" spacing={3}>
            <Typography variant="h2" textAlign="center">
              <Trans>Evaluate Every Possible Scenario</Trans>
            </Typography>
            <Box pt={1} pb={2}>
              <AnimatedDiv isMobile={isMobile} direction="down">
                <Grid container spacing={2}>
                  {CARDS_ITEMS.map((item, key) => renderCard({ ...item, key }))}
                </Grid>
              </AnimatedDiv>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Container py={{ xs: 8, md: 14.5, lg: 8 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Stack spacing={1}>
                {preTitle({ text: "Share Class Radar", align: "left" })}
                <Typography variant="h2">
                  <Trans>Comprehensive Information and Costs</Trans>
                </Typography>
              </Stack>
              <ul style={{ padding: "0 0 0 18px", opacity: "0.6" }}>
                <li>
                  <Trans>
                    Ongoing charges, Distribution, management, subscription, and
                    redemption fees
                  </Trans>
                </li>
                <li>
                  <Trans>Calculate total NET cost in a few clicks</Trans>
                </li>
              </ul>
              <Box
                display="flex"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                {renderRequestButton("Share Class Search")}
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <img
              src={radarImg}
              alt="Share Class Radar"
              width="100%"
              height="100%"
            />
          </Grid>
        </Grid>
      </Container>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Share Class Radar"
          product="share-class-radar"
          hasTemplate={false}
          subtitle={subTitle}
        />
      )}
    </LandingLayout>
  );
}

export default ShareClassRadar;
